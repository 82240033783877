import gql from "graphql-tag";

export const updateProviderByTokenMutationFragment = gql`
	fragment updateProviderByTokenMutationFragment on UpdateOneProviderPayload {
		error {
			message
		}
	}
`;

export const updateAllSmsProviderNotificationsByTokenMutationFragment = gql`
	fragment updateAllSmsProviderNotificationsByTokenMutationFragment on GenericPayload {
		success
	}
`;
