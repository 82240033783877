import { observable, action } from "mobx";

class DollarsCreditsDialog {
	@observable show = false;

	@action
	toggle() {
		this.show = !this.show;
	}

	@action
	onClose() {
		this.show = false;
	}
}

export default DollarsCreditsDialog;
