import { observable, action, runInAction } from "mobx";
import rootService from "../@data/service";

const {
	getProviderByToken,
	updateProviderByToken,
	updateAllSmsProviderNotificationsByToken,
	providerFunnelNextStep,
} = rootService;

class ClaimProfile {
	@observable provider = false;

	constructor(rootStore, { ip }) {
		this.rootStore = rootStore;
		this.ip = ip;
	}

	@action
	async init() {
		try {
			const provider = await getProviderByToken({}, { batch: true });
			runInAction(() => {
				this.provider = provider;
			});
		} catch (err) {
			throw err;
		}
	}

	@action
	async claimProfile({ onSuccess = () => {}, message = "" } = {}) {
		try {
			await Promise.all([
				updateProviderByToken(
					{ record: { ip: this.ip, claimed: true } },
					{ batch: true }
				),
				updateAllSmsProviderNotificationsByToken(
					{ value: true },
					{ batch: true }
				),
			]);

			if (this.provider?.onboardingComplete) {
				const { claimProfileDialogStore } = this.rootStore.stores;
				onSuccess({ message });
				if (claimProfileDialogStore) claimProfileDialogStore.show = false;
				return;
			}

			const { url } = await providerFunnelNextStep({ isOnboarding: true });

			if (url) window.location.href = url;
			else {
				window.location.reload();
			}
		} catch (e) {
			throw e;
		}
	}
}

export default ClaimProfile;
