import { observable, action } from "mobx";
import rootService from "../@data/service";

const { updateProviderByToken } = rootService;

class SearchResultsDialog {
	@observable open = false;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	toggle = () => {
		this.open = !this.open;
	};

	@action
	onClose() {
		this.open = false;
	}

	async legacyOnboardingCompleted() {
		try {
			const { backgroundStore } = this.rootStore.stores;
			await updateProviderByToken({ record: { legacy_onboarded: true } });
			await backgroundStore.init();
		} catch (e) {
			throw e;
		}
	}
}

export default SearchResultsDialog;
