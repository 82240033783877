import { observable, action, runInAction } from "mobx";
import rootService from "../@data/service";

const { updateProviderServiceConfigurationByToken, getServiceSubscriptions } =
	rootService;

class ServiceNowSaasDialogStore {
	@observable open = false;
	@observable subscription;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get serviceConfig() {
		const { backgroundStore } = this.rootStore.stores;
		return backgroundStore.provider?.saas_notify_service_configuration;
	}

	get service() {
		const { backgroundStore } = this.rootStore.stores;
		return backgroundStore.provider?.saas_notify_service_configuration?.service;
	}

	async init() {
		try {
			await updateProviderServiceConfigurationByToken({
				filter: { _id: this.serviceConfig._id },
				record: {
					viewed_saas_notification: true,
				},
			});
			const subscriptions = await getServiceSubscriptions({
				filter: {
					service_business_model_group:
						this.service.business_model_groupMongoID,
				},
			});
			runInAction(() => {
				this.subscription = subscriptions[0];
			});
		} catch (err) {
			throw err;
		}
	}

	@action
	toggle = () => {
		this.open = !this.open;
	};

	@action
	onClose() {
		this.open = false;
		const { backgroundStore } = this.rootStore.stores;
		backgroundStore.refresh();
	}
}

export default ServiceNowSaasDialogStore;
