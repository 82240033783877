import { object, string, array, bool } from "@src/libs/yup";

export const updateProviderByTokenValidation = object({
	record: object({
		claimed: bool().required("claimed is required"),
	}),
});

export const updateAccountByProviderTokenValidation = object({
	record: object({
		email: string()
			.email("Please enter a valid email")
			.required("email is required"),
	}),
});
