import gql from "graphql-tag";

export const updateProviderByTokenMutationFragment = gql`
	fragment updateProviderByTokenMutationFragment on UpdateOneProviderPayload {
		error {
			message
		}
	}
`;

export const updateProviderServiceConfigurationByTokenMutationFragment = gql`
	fragment updateProviderServiceConfigurationByTokenMutationFragment on UpdateOneProviderServiceConfigurationPayload {
		error {
			message
		}
	}
`;

export const createOrUpdateProviderServiceConfigurationsByTokenMutationFragment = gql`
	fragment createOrUpdateProviderServiceConfigurationsByTokenMutationFragment on CreateManyProviderServiceConfigurationPayload {
		error {
			message
		}
	}
`;
