import { observable, runInAction, toJS } from "mobx";

import rootService from "../@data/service";

const { getProviderByToken } = rootService;

class ProBackgroundStore {
	anchor = "left";
	@observable metrics = {};
	@observable drawerState = false;
	@observable provider;

	@observable navState = {
		top: false,
		left: false,
		bottom: false,
		right: false,
	};

	get subscriptions() {
		return toJS(this.provider.subscriptions || []);
	}

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	async init() {
		try {
			const provider = await getProviderByToken({}, { batch: true });
			runInAction(() => {
				this.provider = provider;
				this.metrics = {
					...provider.metrics,
					onboardingComplete: provider.onboardingComplete,
				};
			});
		} catch (err) {
			throw err;
		}
	}

	refresh() {
		return this.init();
	}

	displayPromo(siteConfigs) {
		if (!siteConfigs.saas || !this.provider?.subscriptions) {
			return false;
		}
		return !this.subscriptions.some((sub) => sub.active);
	}
}

export default ProBackgroundStore;
