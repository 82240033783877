import { observable, action } from "mobx";

class ClaimProfileDialog {
	@observable show = false;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	toggle() {
		this.show = !this.show;
	}

	@action
	onClose() {
		this.show = false;
	}
}

export default ClaimProfileDialog;
