import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import { useLocation, useParams } from "react-router-dom";
import withConstants from "@framework/src/libs/withConstants";
import useConstants from "@hooks/useConstants";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import { compose } from "recompose";
import withStyles from "@framework/src/libs/withStyles";
import withStores from "@framework/src/libs/withStores";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import HomeIcon from "@components/Global/Custom/Icons/HomeIcon";
//import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import PaymentIcon from "@material-ui/icons/Payment";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ErrorIcon from "@material-ui/icons/Error";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import StarsIcon from "@material-ui/icons/Stars";
//import RecentActorsOutlinedIcon from "@material-ui/icons/RecentActorsOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import InsertChartOutlinedRoundedIcon from "@material-ui/icons/InsertChartOutlinedRounded";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
//import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import AdjustOutlinedIcon from "@material-ui/icons/AdjustOutlined";
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import MyLocationOutlinedIcon from "@material-ui/icons/MyLocationOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import SpeakerNotesOutlinedIcon from "@material-ui/icons/SpeakerNotesOutlined";
import ClassOutlinedIcon from "@material-ui/icons/ClassOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import AutorenewIcon from "@material-ui/icons/Autorenew";
//import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import DevicesIcon from "@material-ui/icons/Devices";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
//import SpeedIcon from "@material-ui/icons/Speed";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
//import EventNoteIcon from "@material-ui/icons/EventNote";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
//import DirectionsOutlinedIcon from "@material-ui/icons/DirectionsOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RouterComponent from "./RouterComponent";

import { styles, constants, stores } from "./.config";
import { observer } from "mobx-react";

const { LINK_HELP, ENV } = process.env;

import { adjective as proAdjective } from "@language/provider";

const BackgroundNavManagement = compose(
	withStyles(styles),
	withStores(stores),
	withConstants(constants),
	observer
)(({ open }) => {
	const classes = useStyles("Background");
	const { backgroundStore: store, accountStore } = useStores();
	const siteConfigs = useConstants("BackgroundViewModel");
	const { showWhiteboard, saas, referral } = siteConfigs;
	const location = useLocation();
	const params = useParams();

	const opportunities = store.metrics.opportunities ?? 0; // Default to 0 if undefined or null
	const unacceptedDirectLeads = store.metrics.unaccepted_direct_leads ?? 0; // Default to 0 if undefined or null
	const totalOpportunities = opportunities + unacceptedDirectLeads;

	// State for managing submenu expansion
	const [expandedSubmenus, setExpandedSubmenus] = useState({});

	//

	const [openMenu, setOpenMenu] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpenMenu((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpenMenu(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenMenu(false);
		}
	}

	//

	const topNavItems = [
		{
			url: "/pros",
			title: "Home",
			icon: <HomeIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/pros/calendar",
			title: "Bookings",
			icon: <DateRangeOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [
				{
					url: "/pros/appointments",
					title: "Appointments",
					icon: <ListAltOutlinedIcon />,
				},
				{
					url: "/pros/class-schedule",
					title: "Classes",
					icon: <ClassOutlinedIcon />,
				},
				{
					url: "/pros/channels",
					title: "Channels",
					icon: <StorefrontOutlinedIcon />,
				},
				{
					url: "/pros/calendar-settings",
					title: "Settings",
					icon: <SettingsOutlinedIcon />,
				},
			],
		},
		{
			url: "/pros/leads-list",
			title: "Leads",
			icon: <TrendingUpIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			appendEnd: (
				<>
					{totalOpportunities !== 0 && (
						<Badge
							badgeContent={totalOpportunities}
							color="primary"
							overlap="rectangular"
							classes={{
								anchorOriginTopRightRectangular: clsx({
									[classes.navBadge]: open,
									[classes.navBadgeClosed]: !open,
								}),
							}}
						/>
					)}

					{(store.provider?.all_preferences_set === false ||
						store.metrics.onboardingComplete === false) && (
						<Box
							display={"flex"}
							position={"absolute"}
							right={open ? 16 : 4}
							zIndex={1}
							bgcolor={"#FFF"}
							borderRadius={"50%"}
						>
							<ErrorIcon color={"error"} />
						</Box>
					)}
				</>
			),
			subItems: [
				{
					url: "/pros/leads",
					title: "Direct leads",
					subRoutes: [`/pros/leads/accept/${params.quoteKey}`],
					siblingRoutes: [
						"/pros/leads/matched-leads",
						`/pros/quote/${params.requestKey}/${params.leadKey}`,
					],
					icon: <MyLocationOutlinedIcon />,
					isManagment: false,
					appendEnd: !!store.metrics.unaccepted_direct_leads && (
						<Badge
							badgeContent={store.metrics.unaccepted_direct_leads}
							color="primary"
							overlap="rectangular"
							classes={{
								anchorOriginTopRightRectangular: clsx({
									[classes.navBadge]: open,
									[classes.navBadgeClosed]: !open,
								}),
							}}
						/>
					),
				},
				{
					url: "/pros/leads/matched-leads",
					title: "Opportunities",
					subRoutes: [`/pros/quote/${params.requestKey}/${params.leadKey}`],
					siblingRoutes: [
						"/pros/leads",
						`/pros/leads/accept/${params.quoteKey}`,
					],
					icon: <SendOutlinedIcon />,
					isManagment: false,
					appendEnd: store.metrics.opportunities !== 0 && (
						<Badge
							badgeContent={store.metrics.opportunities}
							color="primary"
							overlap="rectangular"
							classes={{
								anchorOriginTopRightRectangular: clsx({
									[classes.navBadge]: open,
									[classes.navBadgeClosed]: !open,
								}),
							}}
						/>
					),
				},
				{
					url: "/pros/inbox",
					title: "Messages",
					subRoutes: ["/pros/inbox/chat"],
					siblingRoutes: [],
					icon: <QuestionAnswerOutlinedIcon />,
					isManagment: false,
					appendEnd: !!store.metrics?.unread_inbox_messages && (
						<Badge
							badgeContent={store.metrics?.unread_inbox_messages}
							color="primary"
							overlap="rectangular"
							classes={{
								anchorOriginTopRightRectangular: clsx({
									[classes.navBadge]: open,
									[classes.navBadgeClosed]: !open,
								}),
							}}
						/>
					),
				},
				{
					url: "/pros/services",
					title: "Services",
					subRoutes: [],
					siblingRoutes: [],
					icon: <FormatListBulletedOutlinedIcon />,
					isManagment: false,
					appendEnd: store.provider?.all_preferences_set === false && (
						<Box
							display={"flex"}
							position={"absolute"}
							right={open ? 16 : 4}
							zIndex={1}
							bgcolor={"#FFF"}
							borderRadius={"50%"}
						>
							<ErrorIcon color={"error"} />
						</Box>
					),
				},
				{
					url: "/pros/profile",
					title: "Profile",
					subRoutes: [],
					siblingRoutes: [],
					icon: <AccountBoxOutlinedIcon />,
					isManagment: false,
					appendEnd: store.metrics.onboardingComplete === false && (
						<Box
							display={"flex"}
							position={"absolute"}
							right={open ? 16 : 4}
							zIndex={1}
							bgcolor={"#FFF"}
							borderRadius={"50%"}
						>
							<ErrorIcon color={"error"} />
						</Box>
					),
				},
			],
		},
		{
			url: "/pros/contacts",
			title: "Contacts",
			icon: <AccountCircleOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],

			subItems: [
				{
					url: "/pros/contacts/members",
					title: "Members",
					icon: <SupervisedUserCircleOutlinedIcon />,
				},
				{
					url: "/pros/reviews",
					title: "Reviews",
					icon: <GradeOutlinedIcon />,
					appendEnd: store.metrics.reviews_count === 0 && (
						<Box
							display={"flex"}
							position={"absolute"}
							right={open ? 16 : 4}
							zIndex={1}
							bgcolor={"#FFF"}
							borderRadius={"50%"}
						>
							<ErrorIcon color={"error"} />
						</Box>
					),
				},
				{
					url: "/pros/contacts/automated-messages",
					title: "Auto messages",
					icon: <MailOutlineIcon />,
				},
				{
					url: "/pros/contacts/notifications-list",
					title: "Notifications",
					icon: <SpeakerNotesOutlinedIcon />,
				},
				{
					url: "/pros/tasks",
					title: "Tasks",
					icon: <AssignmentTurnedInOutlinedIcon />,
				},
			],
		},
		{
			url: "/pros/sales",
			title: "Sales",
			icon: <LocalAtmOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],

			subItems: [
				{
					url: "/pros/take-payment",
					title: "Take payment",
					icon: <MonetizationOnOutlinedIcon />,
				},
				{
					url: "/pros/sales/invoices",
					title: "Invoices",
					icon: <DescriptionOutlinedIcon />,
				},
				{
					url: "/pros/sales/estimates",
					title: "Estimates",
					icon: <InsertDriveFileOutlinedIcon />,
				},
				{
					url: "/pros/sales/memberships-list",
					title: "Memberships",
					icon: <AutorenewIcon />,
				},
				{
					url: "/pros/sales/transactions",
					title: "Transactions",
					icon: <SyncAltIcon />,
					hasDivider: true,
				},

				{
					url: "/pros/upsells",
					title: "Upsells",
					icon: <ArrowUpwardOutlinedIcon />,
				},
				{
					url: "/pros/discounts",
					title: "Discounts",
					icon: <LocalOfferOutlinedIcon />,
				},
				{
					url: "/pros/service-charges",
					title: "Service charges",
					icon: <ShoppingCartOutlinedIcon />,
				},
			],
		},
		{
			url: "/pros/messages",
			title: "Messages",
			icon: <SmsOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/pros/catalog",
			title: "Catalog",
			icon: <FormatListBulletedOutlinedIcon />,
			isManagment: true,

			subRoutes: [],
			siblingRoutes: [],

			subItems: [
				{
					url: "/pros/catalog/services",
					title: "Services",
					icon: <AdjustOutlinedIcon />,
				},
				{
					url: "/pros/catalog/classes",
					title: "Classes",
					icon: <GroupWorkOutlinedIcon />,
				},
				{
					url: "/pros/catalog/memberships",
					title: "Memberships",
					icon: <AutorenewIcon />,
				},
			],
		},
		{
			url: "/pros/website",
			title: "Website",
			icon: <WebOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/pros/payments",
			title: "Payments",
			icon: <CreditCardOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [
				{
					url: "/pros/payments/disputes",
					title: "Disputes",
					icon: <FeedbackOutlinedIcon />,
				},
				{
					url: "/pros/payments/card-readers",
					title: "Card readers",
					icon: <DevicesIcon />,
				},
				{
					url: "/pros/payments/settings",
					title: "Settings",
					icon: <SettingsOutlinedIcon />,
				},
			],
		},
		{
			url: "/pros/team",
			title: "Team",
			icon: <PeopleAltOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],

			subItems: [
				{
					url: "/pros/team/availability",
					title: "Availability",
					icon: <AccessTimeIcon />,
				},
			],
		},
		{
			url: "/pros/reports",
			title: "Reports",
			icon: (
				<InsertChartOutlinedRoundedIcon style={{ transform: "scaleX(-1)" }} />
			),
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/pros/settings",
			title: "Settings",
			icon: <SettingsOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],

			subItems: [
				{
					url: "/pros/integrations",
					title: "App integrations",
					icon: <CategoryOutlinedIcon />,
				},
			],
		},
	];

	const saasItems = [
		{
			url: `/pros/${store.provider?.onboardingService?.key}/upgrade`,
			title: "Upgrade",
			subRoutes: [],
			siblingRoutes: [],
			icon: <TrendingUpIcon />,
			isManagment: true,
			appendEnd: store.metrics.reviews_count === 0 && (
				<Box
					display={"flex"}
					position={"absolute"}
					right={open ? 16 : 4}
					zIndex={1}
					bgcolor={"#FFF"}
					borderRadius={"50%"}
				>
					<StarsIcon style={{ color: "#635ac7" }} />
				</Box>
			),
		},
	];

	const whiteboardNavItems = [
		{
			url: {
				pathname:
					"https://woowhiteboard.com/online-whiteboard-for-teaching?utm_source=liaison&utm_medium=nav-client&utm_campaign=nav-client",
			},
			title: "Whiteboard",
			subRoutes: [],
			siblingRoutes: [],
			icon: <DashboardOutlinedIcon />,
			isManagment: false,
			target: "_blank",
		},
	];

	const referralItems = [
		{
			url: "/pros/referrals",
			title: `Refer pros. Get $50.`,
			subRoutes: [],
			siblingRoutes: [],
			icon: <AttachMoneyIcon />,
			isManagment: false,
			target: "_blank",
		},
	];

	const mobileNavItems = [
		{
			url: "/pros/settings/payments",
			title: "Billing",
			subRoutes: [],
			siblingRoutes: [],
			icon: <PaymentIcon />,
			isManagment: false,
		},
		{
			url: "/pros/settings",
			title: "Settings",
			subRoutes: [],
			siblingRoutes: [],
			icon: <SettingsIcon />,
			isManagment: false,
		},
		{
			url: { pathname: LINK_HELP },
			title: "Support",
			subRoutes: [],
			siblingRoutes: [],
			icon: <HelpOutlineIcon />,
			isManagment: false,
			target: "_blank",
		},
	];

	// Automatically expand submenu for the current main item
	useEffect(() => {
		const currentPath = location.pathname;
		const foundIndex = topNavItems.findIndex(
			(item) =>
				item.url === currentPath ||
				(item.subItems &&
					item.subItems.some((subItem) => subItem.url === currentPath))
		);

		if (foundIndex !== -1) {
			const submenuKey = `submenu-${foundIndex}`;
			// Check if the submenu is already expanded
			setExpandedSubmenus((prevExpandedSubmenus) => {
				// If the submenu is already expanded, do not update the state
				if (prevExpandedSubmenus[submenuKey]) {
					return prevExpandedSubmenus;
				}
				// Otherwise, update the state to expand the submenu
				return {
					...prevExpandedSubmenus,
					[submenuKey]: true,
				};
			});
		}
		// Make sure to include dependencies that change when needed but do not cause infinite loops
	}, [location.pathname]); // Only re-run the effect if the pathname changes

	const handleSubmenuToggle = (submenu) => {
		setExpandedSubmenus((prevExpandedSubmenus) => ({
			...prevExpandedSubmenus,
			[submenu]: !prevExpandedSubmenus[submenu],
		}));
	};

	const renderSubItems = (subItems, submenuKey) => (
		<Collapse in={expandedSubmenus[submenuKey]} timeout="auto" unmountOnExit>
			<List component="div" disablePadding>
				{subItems.map((subItem) => {
					const isSelected = subItem.url === location.pathname;

					return (
						<>
							<Tooltip
								key={subItem.title}
								enterDelay={200}
								title={subItem.title}
								arrow
								TransitionComponent={Zoom}
								placement={"right"}
								classes={{ tooltip: clsx({ [classes.adminToolTips]: open }) }}
							>
								<ListItem
									button
									component="a"
									href={subItem.url}
									selected={isSelected}
									classes={{
										root: open
											? classes.listPanelItemNested
											: classes.listPanelItemNestedClosed,
										button: clsx({
											[classes.adminListPanelButton]: open,

											[classes.adminListPanelItemActive]: isSelected && open,
											[classes.adminListPanelItem]: !isSelected,
											[classes.adminListPanelItemActiveClosed]:
												!open && isSelected,
										}),
									}}
								>
									<ListItemIcon
										className={
											!open
												? classes.adminListItemIconWhite
												: isSelected
												? classes.adminListItemIconActive
												: classes.adminListItemIcon
										}
									>
										{subItem.icon}
									</ListItemIcon>
									<ListItemText
										className={
											isSelected
												? classes.adminListItemTextActive
												: classes.adminListItemTextNested
										}
										disableTypography
										primary={subItem.title}
									/>
									{!!subItem.appendEnd && subItem.appendEnd}
								</ListItem>
							</Tooltip>
							{subItem.hasDivider && (
								<>
									{open && (
										<>
											<Box py={3}>
												<Divider />
											</Box>
											<ListItem
												disabled
												className={classes.listPrimaryDisabled}
											>
												Options
											</ListItem>
										</>
									)}
								</>
							)}
						</>
					);
				})}
			</List>
		</Collapse>
	);

	const renderNavItems = (items) =>
		items.map((item, index) => {
			const isSelected = [item.url, ...item.subRoutes].includes(
				location.pathname
			);
			const isWithinSPA = [
				item.url,
				...item.subRoutes,
				...item.siblingRoutes,
			].includes(location.pathname);
			const hasSubItems = item.subItems && item.subItems.length > 0;
			const submenuKey = `submenu-${index}`;

			return (
				<React.Fragment key={item.title}>
					<RouterComponent
						isWithinSPA={true}
						url={item.url}
						target={item.target}
					>
						<Tooltip
							enterDelay={200}
							title={item.title}
							arrow
							TransitionComponent={Zoom}
							placement={"right"}
							classes={{ tooltip: clsx({ [classes.adminToolTips]: open }) }}
						>
							<ListItem
								button
								selected={isSelected}
								onClick={() => hasSubItems && handleSubmenuToggle(submenuKey)}
								classes={{
									root: open
										? classes.listPanelItem
										: classes.listPanelItemClosed,
									button: clsx({
										[classes.adminListPanelButton]: open,
									}),
									selected: !open
										? classes.adminListPanelItemActiveClosed
										: isSelected
										? classes.adminListPanelItemActive
										: classes.adminListPanelItem,
								}}
							>
								<ListItemIcon
									className={
										!open
											? classes.adminListItemIconWhite
											: isSelected
											? classes.adminListItemIconActive
											: classes.adminListItemIcon
									}
								>
									{item.icon}
								</ListItemIcon>
								<ListItemText
									className={
										isSelected
											? classes.adminListItemTextActive
											: classes.adminListItemText
									}
									disableTypography
									primary={item.title}
								/>
								{!!item.appendEnd && item.appendEnd}
							</ListItem>
						</Tooltip>
					</RouterComponent>
					{hasSubItems && renderSubItems(item.subItems, submenuKey)}
				</React.Fragment>
			);
		});

	return (
		<React.Fragment>
			<>
				<List
					classes={{
						root: clsx({
							[classes.adminList]: open,
						}),
					}}
				>
					{open && (
						<>
							<Tooltip
								enterDelay={200}
								title="Add new"
								arrow
								TransitionComponent={Zoom}
								placement={"right"}
								classes={{ tooltip: clsx({ [classes.adminToolTips]: open }) }}
							>
								<ListItem
									button
									ref={anchorRef}
									aria-controls={openMenu ? "menu-list-grow" : undefined}
									aria-haspopup="true"
									onClick={handleToggle}
									classes={{
										root: classes.listPanelItem,
										button: clsx({
											[classes.adminListPanelButton]: open,
										}),
										selected: classes.adminListPanelItem,
									}}
								>
									<ListItemIcon className={classes.adminListItemIcon}>
										<AddOutlinedIcon />
									</ListItemIcon>
									<ListItemText
										className={classes.adminListItemText}
										disableTypography
										primary={"Add new"}
									/>
								</ListItem>
							</Tooltip>

							<Popper
								open={openMenu}
								anchorEl={anchorRef.current}
								role={undefined}
								transition
								disablePortal
								placement={"bottom-end"}
								style={{ zIndex: 1500 }}
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin:
												placement === "bottom-end"
													? "bottom-end"
													: "bottom-end",
										}}
									>
										<Paper elevation={6} style={{ width: 230 }}>
											<ClickAwayListener onClickAway={handleClose}>
												<MenuList
													id="menu-list-grow"
													onKeyDown={handleListKeyDown}
												>
													<MenuItem
														component={"a"}
														href="/xxx"
														onClick={handleClose}
													>
														<ListItemIcon className={classes.adminListItemIcon}>
															<AccountCircleOutlinedIcon />
														</ListItemIcon>

														<ListItemText
															classes={{
																primary: classes.listPrimary,
																secondary: classes.listSecondary,
															}}
															primary={"Contact"}
														/>
													</MenuItem>

													<MenuItem
														component={"a"}
														href="/xxx"
														onClick={handleClose}
													>
														<ListItemIcon className={classes.adminListItemIcon}>
															<DateRangeOutlinedIcon />
														</ListItemIcon>

														<ListItemText
															classes={{
																primary: classes.listPrimary,
																secondary: classes.listSecondary,
															}}
															primary={"Appointment"}
														/>
													</MenuItem>

													<MenuItem
														component={"a"}
														href="/xxx"
														onClick={handleClose}
													>
														<ListItemIcon className={classes.adminListItemIcon}>
															<ClassOutlinedIcon />
														</ListItemIcon>

														<ListItemText
															classes={{
																primary: classes.listPrimary,
																secondary: classes.listSecondary,
															}}
															primary={"Class enrollment"}
														/>
													</MenuItem>

													<MenuItem
														component={"a"}
														href="/xxx"
														onClick={handleClose}
													>
														<ListItemIcon className={classes.adminListItemIcon}>
															<LocalAtmOutlinedIcon />
														</ListItemIcon>

														<ListItemText
															classes={{
																primary: classes.listPrimary,
																secondary: classes.listSecondary,
															}}
															primary={"Sale"}
														/>
													</MenuItem>

													<MenuItem
														component={"a"}
														href="/xxx"
														onClick={handleClose}
													>
														<ListItemIcon className={classes.adminListItemIcon}>
															<DescriptionOutlinedIcon />
														</ListItemIcon>

														<ListItemText
															classes={{
																primary: classes.listPrimary,
																secondary: classes.listSecondary,
															}}
															primary={"Invoice"}
														/>
													</MenuItem>

													<MenuItem
														component={"a"}
														href="/xxx"
														onClick={handleClose}
													>
														<ListItemIcon className={classes.adminListItemIcon}>
															<InsertDriveFileOutlinedIcon />
														</ListItemIcon>

														<ListItemText
															classes={{
																primary: classes.listPrimary,
																secondary: classes.listSecondary,
															}}
															primary={"Estimate"}
														/>
													</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</>
					)}

					{renderNavItems(topNavItems)}
					{showWhiteboard && <>{renderNavItems(whiteboardNavItems)}</>}
					{saas && <>{renderNavItems(saasItems)}</>}
				</List>
				<Box display={{ xs: "block", sm: "block", md: "none" }}>
					<Divider light />

					<List>
						{renderNavItems(mobileNavItems)}
						{referral && <>{renderNavItems(referralItems)}</>}
					</List>

					<Divider light />

					<List>
						<Tooltip
							enterDelay={200}
							title="Sign out"
							arrow
							TransitionComponent={Zoom}
							placement={"right"}
							classes={{
								tooltip: clsx({
									[classes.adminToolTips]: open,
								}),
							}}
						>
							<ListItem button key={"Sign out"} onClick={accountStore.logout}>
								<ListItemIcon className={classes.adminListItemIcon}>
									<ExitToAppIcon />
								</ListItemIcon>

								<ListItemText
									className={classes.adminListItemText}
									disableTypography
									primary={"Sign out"}
								/>
							</ListItem>
						</Tooltip>
					</List>
				</Box>
				{open && store.displayPromo(siteConfigs) && (
					<Box p={4} mt={6}>
						<ButtonBase
							href={`/pros/${store.provider?.onboardingService?.key}/upgrade`}
						>
							<Paper elevation={4}>
								<Box p={4} whiteSpace={"pre-line"}>
									<Box fontSize={20} fontWeight={700} lineHeight={1.3} mb={2}>
										Want to get 3X more leads?
									</Box>
									<Box fontSize={14} lineHeight={1.5} mb={4}>
										{proAdjective.plural.ucFirst()} who’ve subscribed to our
										premium marketing plan get 3X the number of leads.
									</Box>
									<Button
										color="primary"
										variant="contained"
										disableElevation
										fullWidth
										href={`/pros/${store.provider?.onboardingService?.key}/upgrade`}
									>
										Learn more
									</Button>
								</Box>
							</Paper>
						</ButtonBase>
					</Box>
				)}
			</>
		</React.Fragment>
	);
});

BackgroundNavManagement.propTypes = {
	open: PropTypes.bool,
};

export default BackgroundNavManagement;
