import { observable, action, computed, runInAction } from "mobx";

import rootService from "../@data/service";
const {
	getService,
	getProviderServiceConfigurationsByToken,
	createOrUpdateProviderServiceConfigurationsByToken,
	updateProviderByToken,
} = rootService;

class AddMoreServicesDialog {
	@observable show = false;
	@observable relations = [];
	@observable selectedServiceIds = [];
	@observable serviceConfigurations = [];

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get onboardingServiceId() {
		const { backgroundStore } = this.rootStore.stores;
		return backgroundStore?.provider?.onboardingService?._id;
	}

	async init() {
		if (!this.onboardingServiceId) return;
		try {
			//service configs only return non-midtail active services
			const [serviceConfigurations, service] = await Promise.all([
				getProviderServiceConfigurationsByToken({}, { batch: true }),
				getService(
					{ filter: { _id: this.onboardingServiceId } },
					{ batch: true }
				),
			]);

			runInAction(() => {
				this.serviceConfigurations = serviceConfigurations;
				if (service)
					this.relations = service.relations.filter(
						(relation) =>
							!serviceConfigurations.find(
								(config) => config.serviceMongoID === relation.consequent._id
							)
					);
			});
		} catch (e) {
			throw e;
		}
	}

	@action
	toggle() {
		if (this.relations.length === 0) return;
		this.show = !this.show;
	}

	@action
	open() {
		if (this.relations.length === 0) return;
		this.show = true;
	}

	@action
	add(serviceId) {
		const serviceConfig = this.serviceConfigurations.find(
			(config) => config.serviceMongoID === serviceId
		);
		if (serviceConfig) return;
		const hasRelationId = this.selectedServiceIds.includes(serviceId);
		if (hasRelationId) return;
		this.selectedServiceIds.push(serviceId);
	}

	@action
	remove(serviceId) {
		const serviceConfigIndex = this.serviceConfigurations.findIndex(
			(config) => config.serviceMongoID === serviceId
		);
		if (serviceConfigIndex !== -1) {
			this.serviceConfigurations[serviceConfigIndex].active = false;
			return;
		}
		this.selectedServiceIds = this.selectedServiceIds.filter(
			(id) => id !== serviceId
		);
	}

	isChecked(serviceId) {
		const serviceConfig = this.serviceConfigurations.find(
			(config) => config.serviceMongoID === serviceId
		);
		if (serviceConfig) return true;
		return this.selectedServiceIds.includes(serviceId);
	}

	async stopAskingForMoreServices() {
		try {
			const now = new Date();
			await updateProviderByToken({
				record: {
					asked_for_more_services: true,
					last_time_asked_for_more_services: now.toISOString(),
				},
			});
			const { backgroundStore } = this.rootStore.stores;
			await backgroundStore.refresh();
		} catch (e) {
			throw e;
		}
	}

	async save() {
		try {
			const records = this.selectedServiceIds
				.map((serviceId) => ({
					service: serviceId,
					active: true,
				}))
				.concat(
					this.serviceConfigurations.map((config) => ({
						service: config.serviceMongoID,
						active: config.active,
					}))
				);
			await createOrUpdateProviderServiceConfigurationsByToken({
				records,
			});

			await this.stopAskingForMoreServices();
		} catch (e) {
			throw e;
		}
	}

	@action
	onClose() {
		this.show = false;
	}
}

export default AddMoreServicesDialog;
