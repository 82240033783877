import { object, string, array, bool } from "@src/libs/yup";

export const createOrUpdateProviderServiceConfigurationsByTokenValidation =
	object().shape({
		records: array()
			.of(
				object().shape({
					service: string().objectid().required(),
					active: bool().required(),
				})
			)
			.required(),
	});
