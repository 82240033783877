import gql from "graphql-tag";

export const providerByTokenQueryFragment = gql`
	fragment providerByTokenQueryFragment on Provider {
		_id
		legacy
		formatted_balance
		legacy_onboarded
		all_preferences_set
		saas_notify_service_configuration {
			_id
			service {
				_id
				key
				name
				business_model_groupMongoID
			}
		}
		metrics {
			reviews_count
			opportunities
			unaccepted_direct_leads
			unread_inbox_messages
		}
		onboardingService {
			_id
			key
		}
		subscriptions {
			active
		}
		claimed
		track {
			source
		}
		asked_for_more_services
		onboardingComplete
	}
`;

export const serviceQueryFragment = gql`
	fragment serviceQueryFragment on Service {
		relations(limit: 20) {
			_id
			consequent {
				_id
				name_proper
				name
			}
		}
	}
`;

export const providerServiceConfigurationsByTokenQueryFragment = gql`
	fragment providerServiceConfigurationsByTokenQueryFragment on ProviderServiceConfiguration {
		onboardingService
		serviceMongoID
		active
	}
`;

export const serviceSubscriptionsQueryFragment = gql`
	fragment serviceSubscriptionsQueryFragment on ServiceSubscription {
		features
	}
`;
