import { parseConstants, parseStyles, parseStores } from "@src/libs/parser";

const claimProfileStores = parseStores(
	require.context("../ClaimProfile", true, /\.(store)\.(js)$/)
);

export const stores = {
	...parseStores(require.context("./", true, /\.(store)\.(js)$/)),
	...claimProfileStores,
};
export const styles = parseStyles(
	require.context("./", true, /\.(styles)\.(js)$/)
);
export const constants = parseConstants(
	require.context("./", true, /\.(json)$/)
);
